import React, { createContext, ReactNode, useState } from "react";
import {
  BillableRequestStatus,
  GetBillableRequestClients200Response,
  GetBillableRequestNumbers200Response,
  GetSessionBillingCycles200ResponseInner,
} from "@practice/sdk";
import { noop } from "lodash";
import { useSWRConfig } from "swr";

import { useAuth } from "@contexts/auth";
import {
  GetBillableRequestsQueryParams,
  useGetBillableRequestClients,
  useGetBillableRequestNumbers,
  useGetBillableRequests,
  useGetSessionBillingCycles,
} from "@hooks/data/billable-request";

import LoadingSpinner from "@components/LoadingSpinner";

import { ModeType } from "./BillableRequestsModal";

// Define the shape of your context value
interface BillableRequestsContextValue {
  filters: GetBillableRequestsQueryParams;
  setFilters: (filters: GetBillableRequestsQueryParams) => void;
  clients: GetBillableRequestClients200Response | undefined;
  numbers: GetBillableRequestNumbers200Response | undefined;
  loadingClients?: boolean;
  loadingNumbers?: boolean;
  refresh: () => void;
  mode?: ModeType;
  defaultFilters?: GetBillableRequestsQueryParams;
}

// Create the context
export const BillableRequestsContext =
  createContext<BillableRequestsContextValue>({
    filters: { limit: 20, status: BillableRequestStatus.Submitted },
    setFilters: noop,
    numbers: undefined,
    clients: undefined,
    loadingClients: false,
    loadingNumbers: false,
    refresh: noop,
    mode: undefined,
    defaultFilters: undefined,
  });

export const useGetBillableRequestsContext = () => {
  return React.useContext(BillableRequestsContext);
};

export const BillableRequestsProvider: React.FC<{
  children: ReactNode;
  mode: ModeType;
  defaultFilters?: Pick<
    GetBillableRequestsQueryParams,
    "accountId" | "cycleEnd" | "cycleStart" | "clientId" | "billingRateId"
  >;
}> = ({ children, mode, defaultFilters }) => {
  const {
    data: cycles,
    isValidating,
    isLoading,
  } = useGetSessionBillingCycles();

  if (isLoading || isValidating)
    return (
      <div className="mb-auto">
        <LoadingSpinner className="m-auto" />
      </div>
    );

  return (
    <InnerContext
      cycles={
        cycles?.map((d) => ({
          start: new Date(d.start),
          end: new Date(d.end),
          label: d.label,
        })) ?? []
      }
      mode={mode}
      defaultFilters={defaultFilters}
    >
      {children}
    </InnerContext>
  );
};

export const useFetchBillableRequests = () => {
  const { filters } = useGetBillableRequestsContext();
  return useGetBillableRequests(filters);
};

// Create the provider component
export const InnerContext: React.FC<{
  children: ReactNode;
  cycles: GetSessionBillingCycles200ResponseInner[];
  mode: ModeType;
  defaultFilters?: Pick<GetBillableRequestsQueryParams, "accountId">;
}> = ({ children, cycles, mode, defaultFilters = {} }) => {
  const { aid } = useAuth();
  const firstCycle = cycles[0];
  const { mutate, cache } = useSWRConfig();

  const [filters, setFilters] = useState<GetBillableRequestsQueryParams>({
    limit: 20,
    cycleStart: firstCycle?.start,
    cycleEnd: firstCycle?.end,
    ...defaultFilters,
    accountId: defaultFilters.accountId,
    status: BillableRequestStatus.Submitted,
    // if mode coach, add aid to filters
    ...(mode === ModeType.COACH && { accountId: aid }),
  });

  const {
    data: numbers,
    isValidating: isValidatingNumbers,
    isLoading: isLoadingNumbers,
  } = useGetBillableRequestNumbers({
    cycleEnd: filters.cycleEnd,
    cycleStart: filters.cycleStart,
    accountId: defaultFilters.accountId ?? filters.accountId,
    clientId: filters.clientId,
    billingRateId: filters.billingRateId,
  });

  const {
    data: clients,
    isValidating: isValidatingClients,
    isLoading: isLoadingClients,
  } = useGetBillableRequestClients({
    accountId: defaultFilters.accountId ?? filters.accountId,
  });

  const refresh = () => {
    const promises: Promise<void>[] = [];
    for (const key of cache.keys()) {
      if (key.includes("/billable-requests")) {
        promises.push(mutate(key));
      }
    }
    return Promise.all(promises);
  };

  const value: BillableRequestsContextValue = {
    filters,
    setFilters,
    numbers,
    clients,
    loadingNumbers: isValidatingNumbers || isLoadingNumbers,
    loadingClients: isValidatingClients || isLoadingClients,
    refresh,
    mode,
    defaultFilters,
  };

  return (
    <BillableRequestsContext.Provider value={value}>
      {children}
    </BillableRequestsContext.Provider>
  );
};
