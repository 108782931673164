import { ReactNode, useCallback } from "react";
import classNames from "classnames";
import { debounce } from "lodash";

import LoadingSpinner from "./LoadingSpinner";

export const Trigger = ({
  isLoading,
  onIntersection,
  loadingPlaceholder,
  className,
}: {
  isLoading: boolean;
  onIntersection: () => void;
  loadingPlaceholder?: ReactNode;
  className?: string;
}) => {
  const TriggerRef = useCallback(
    debounce((node: any) => {
      if (!node) return;

      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);
            observer.disconnect();
            !isLoading && onIntersection();
          }
        });
      }, options);

      observer.observe(node);
    }, 500),
    [onIntersection]
  );

  return (
    <div
      className={classNames(
        "flex flex-col justify-between gap-y-2 h-full w-full items-center mt-6 mb-6",
        className
      )}
      ref={TriggerRef}
    >
      {isLoading &&
        (loadingPlaceholder ?? <LoadingSpinner className="flex-1" />)}
    </div>
  );
};
