import React, { useState } from "react";
import { BillableRequestStatus } from "@practice/sdk";
import classNames from "classnames";
import { isUndefined } from "lodash";
import moment from "moment";

import { useGetSessionBillingCycles } from "@hooks/data/billable-request";

import ArrowPaginationButtons from "@components/Buttons/ArrowPaginationButtons";
import Selectbox from "@components/Form/Selecbox";

import BillableRequestFilterDropdown from "./BillableRequestFilterDropdown";
import { useGetBillableRequestsContext } from "./BillableRequestsContext";

const MAP: Record<BillableRequestStatus, string> = {
  [BillableRequestStatus.Submitted]: "Pending",
  [BillableRequestStatus.Approved]: "Approved",
  [BillableRequestStatus.Rejected]: "Rejected",
};

const Tab: React.FC<{
  status: BillableRequestStatus;
}> = ({ status }) => {
  const { numbers, loadingNumbers, filters, setFilters } =
    useGetBillableRequestsContext();

  const selectedTab = filters.status;
  const count = numbers?.[status];
  const title = MAP[status];
  const active = selectedTab === status;
  const onClick = () => {
    setFilters({ ...filters, status });
  };

  return (
    <button
      className={classNames(
        "py-2 px-4 font-medium rounded-md mr-4",
        active ? "bg-action-900" : "text-grey-500 bg-grey-950"
      )}
      onClick={onClick}
    >
      {title}
      {!isUndefined(count) && !loadingNumbers && (
        <span
          className={classNames(
            "ml-3 rounded text-xs py-0.5 px-1",
            active ? "bg-action-600" : "bg-grey-900 text-grey-500"
          )}
        >
          {count}
        </span>
      )}
    </button>
  );
};

interface BillableRequestFiltersProps {
  hideMemberFilter?: boolean;
}

const BillableRequestFilters: React.FC<BillableRequestFiltersProps> = ({
  hideMemberFilter,
}) => {
  const { filters, setFilters, defaultFilters } =
    useGetBillableRequestsContext();

  const { data: cycles } = useGetSessionBillingCycles();
  const [isAll, setIsAll] = useState<boolean>(false);

  const findIndex = (date: Date) => {
    return cycles!.findIndex(
      (d) =>
        moment(d.start).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
    );
  };

  const currentIndex = findIndex(filters.cycleStart!);

  const disableNextCycle = currentIndex === cycles!.length - 1;
  const disablePreviousCycle = currentIndex === 0;

  const setCycle = (index: number | "all") => {
    const isAll = index === "all";
    if (isAll) {
      const lastCycle = cycles![cycles!.length - 1];
      setIsAll(true);
      setFilters({
        ...filters,
        cycleEnd: new Date(cycles![0].end),
        cycleStart: new Date(lastCycle.start),
      });
    } else {
      setIsAll(false);
      setFilters({
        ...filters,
        cycleEnd: new Date(cycles![index].end),
        cycleStart: new Date(cycles![index].start),
      });
    }
  };

  const cycleOptions = [
    { label: "All cycles", value: "all" },
    ...cycles!.map((d) => ({
      label: d.label,
      value: d.start,
    })),
  ];

  return (
    <div className="h-20 flex items-center">
      {Object.values(BillableRequestStatus).map((status) => (
        <Tab key={status} status={status} />
      ))}
      <div className="ml-auto flex items-center space-x-2">
        {!defaultFilters?.cycleStart && (
          <>
            <ArrowPaginationButtons
              className="hidden sm:flex"
              onClickNext={() => setCycle(currentIndex + 1)}
              onClickPrevious={() => setCycle(currentIndex - 1)}
              disableNext={disableNextCycle}
              disablePrevious={disablePreviousCycle}
            />
            {!isNaN(currentIndex) && (
              <Selectbox
                className="hidden sm:block"
                options={cycleOptions}
                value={isAll ? "all" : cycles![currentIndex]?.start ?? "all"}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "all") setCycle("all");
                  else {
                    const selectedCycle = findIndex(new Date(value));
                    setCycle(selectedCycle);
                  }
                }}
              />
            )}
          </>
        )}
        <BillableRequestFilterDropdown hideMemberFilter={hideMemberFilter} />
      </div>
    </div>
  );
};

export default BillableRequestFilters;
