import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.22192"
        y="19.364"
        width="22"
        height="2"
        transform="rotate(-45 3.22192 19.364)"
        fill="#8B5114"
      />
      <circle cx="7" cy="7" r="3" stroke="#8B5114" strokeWidth="2" />
      <circle cx="17" cy="17" r="3" stroke="#8B5114" strokeWidth="2" />
    </svg>
  );
};

export default Icon;
