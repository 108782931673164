import { useEffect, useState } from "react";

import { copy } from "@lib/clipboard";
import { getCurrentURIFromServer } from "@lib/utils";

import DiscountIcon from "@components/Icons/DiscountIcon";
import DuplicateIcon from "@components/Icons/DuplicateIcon";
import ShareIcon from "@components/Icons/ShareIcon";
import { Modal, ModalContent, ModalTitle } from "@components/Modal";
import StepsItem from "@components/StepsItem";
import TypeIllustration from "@components/TypeIllustration";

interface ReferralModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  referralCode: string;
}

const getButtonClass = () =>
  "mt-2 h-12 w-full flex justify-between items-center text-left text-sm px-6 py-3 leading-5 text-black-ink bg-action-950 hover:bg-action-800 active:bg-action-700 focus:outline-none rounded-md focus:bg-gray-100 disabled:text-grey-800 disabled:text-grey-800 disabled:bg-grey-950 disabled:cursor-not-allowed";

const ReferralModal: React.FC<ReferralModalProps> = ({
  show,
  setShow,
  referralCode,
}: ReferralModalProps) => {
  const referralLink = `${getCurrentURIFromServer()}/join/${referralCode}`;
  const [copyLinkLabel, setCopyLinkLabel] = useState(null);
  const stepsTitleClassNames = "text-gray-600 text-sm";

  useEffect(() => {
    setCopyLinkLabel(referralLink);
  }, [referralLink]);

  const onCloseModal = (val: boolean) => {
    setShow(val);
  };

  const handleCopyLink = () => {
    copy({
      value: referralLink,
      callback: () => {
        const text = copyLinkLabel;
        setCopyLinkLabel("Copied link");
        setTimeout(() => setCopyLinkLabel(text), 1000);
      },
    });
  };

  return (
    <Modal
      show={show}
      toggleShow={onCloseModal}
      maxWidth="max-w-sm overflow-visible"
    >
      <div className="flex flex-col items-center text-center">
        <ModalTitle className="flex flex-col items-center pt-10">
          <TypeIllustration
            slug="ill_flame_big"
            fileType="svg"
            className="w-16"
          />
          <h3 className="text-3xl mt-3 mb-2 leading-tight">Refer & Earn</h3>
        </ModalTitle>

        <ModalContent className="w-full">
          Share Practice and earn $100
          <div className="flex flex-col mt-3 text-left">
            <div className="grid grid-cols-6 w-full pt-5">
              <StepsItem
                icon={<ShareIcon className="m-1" />}
                title="Share your referral link"
                showSeparator
                titleClassNames={stepsTitleClassNames}
                iconWrapperClassNames="bg-gray-200 rounded-full"
              />
              <StepsItem
                icon={<DiscountIcon className="m-1" />}
                title="For each new referral that signs up for Practice, we'll send you $100 cash "
                titleClassNames={stepsTitleClassNames}
                iconWrapperClassNames="bg-yellow-300 rounded-full"
              />
            </div>
            <div className="flex flex-col bg-action-900 px-2 py-3 rounded-lg">
              <span className="text-center font-medium">
                Your referral link
              </span>
              <button
                className={getButtonClass()}
                onClick={handleCopyLink}
                data-heap-event-name="share_referral_link"
              >
                {copyLinkLabel}
                <DuplicateIcon />
              </button>
            </div>
          </div>
        </ModalContent>
      </div>
    </Modal>
  );
};

export default ReferralModal;
