import { FC, useState } from "react";
import { kebabCase } from "lodash";
import { useRouter } from "next/router";
import { TodoType } from "types/db/todos";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useAllClientsTodos from "@hooks/use-clients-todos";
import useTodos from "@hooks/use-todos";
import useTodosFilter from "@hooks/use-todos-filter";
import { filterTodos } from "@lib/utils/todos";

import { Button } from "@components/Button";
import CloseIcon from "@components/Icons/CloseIcon";
import EmptyTodoIcon from "@components/Icons/EmptyTodoIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import TodoIcon from "@components/Icons/TodoIcon";
import SideModal from "@components/SideModal/SideModal";
import Tabs from "@components/Tabs/Tabs";
import Todos from "@components/Todos/Todos";
import { Tooltip } from "@components/Tooltips/Tooltip";

import ClientTodoSection from "./ClientTodoSection";
import TodoEmptyState from "./TodoEmptyState";
import TodoTags from "./TodoTags";

const PrivateTodosSideModal: FC = () => {
  const {
    oid,
    aid,
    organizationAccounts: {
      accounts: members,
      elevatedMembers,
      fullAccessMembers,
    },
  } = useAuth();
  const team = (members?.length || 0) > 1;

  const { hasFullAccess } = useAccessType();
  const elevatedUser = elevatedMembers?.find((m) => m.id === aid);

  const { clientTodos, clientsIncompleteTodos, clientsWithTodos } =
    useAllClientsTodos(oid!, aid!);
  const { todos, createTodo } = useTodos({
    coachUserId: oid!,
    ...(!hasFullAccess && { limitedTo: aid }),
  });

  const assigneeOptions = elevatedUser
    ? [...(fullAccessMembers || []), elevatedUser]
    : [...(members || [])];

  const router = useRouter();
  const { query } = router;
  const activeTab = query?.todos || "internal";
  const {
    SortDropdown,
    completeTodos,
    incompleteTodos,
    selectedSort,
    filtersState,
  } = useTodosFilter(todos, undefined, !!elevatedUser);

  const clientIncompleteTodos = filterTodos(
    clientsIncompleteTodos,
    filtersState,
    selectedSort
  );

  const allClientTodos = filterTodos(clientTodos, filtersState, selectedSort);

  const [showModal, setShowModal] = useState<boolean>(false);
  const handleToggleShow = () => setShowModal(!showModal);

  const getHeader = (tab: string, customListTodos: TodoType[]) => (
    <div className="flex items-center">
      <h1>{tab}</h1>
      <TodoTags
        tagColor={activeTab !== kebabCase(tab) ? "dark-grey" : undefined}
        customListTodos={customListTodos}
        tagClassNames="!py-0 px-0 text-sm"
      />
    </div>
  );

  const title = (
    <div className="flex items-center gap-1">
      <h1>To-dos</h1>
      <Tooltip
        placement="bottom-start"
        trigger={<InfoIcon className="text-grey-500 w-5 h-5" />}
      >
        <div className="flex flex-col gap-2">
          Internal to-dos are not visible to clients while client to-dos
          aggregate all to-dos from your active clients.
        </div>
      </Tooltip>
    </div>
  );

  return (
    <SideModal
      show={showModal}
      toggleShow={handleToggleShow}
      trigger={
        <Button
          className="fixed z-40 bottom-6 right-6 shadow-thin group w-40"
          icon={<TodoIcon />}
          rightIcon={
            <TodoTags tagClassNames="!py-0" customListTodos={incompleteTodos} />
          }
          white
          circle
          data-heap-event-name="todos_side_modal_open_button_clicked"
          onClick={handleToggleShow}
        >
          To-dos
        </Button>
      }
    >
      <>
        <div className="w-full flex items-center px-4 mb-8 gap-4">
          <div className="sm:absolute sm:bottom-6 sm:right-6">
            <Button
              icon={<CloseIcon />}
              circle
              square
              onClick={handleToggleShow}
            />
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="text-xl">{title}</div> <div>{SortDropdown}</div>
          </div>
        </div>
        <Tabs
          tabClassNames="rounded-full !text-base ml-4 mr-0"
          inactiveTabClassNames="!bg-grey-950"
          queryParamName="todos"
        >
          <Tabs.Item
            name="Internal"
            header={getHeader("Internal", incompleteTodos)}
          >
            <div className="mt-4">
              {!incompleteTodos?.length && (
                <TodoEmptyState
                  title={
                    team
                      ? "Teamwork makes the dream work"
                      : "Never miss a thing"
                  }
                  description={
                    team
                      ? "Assign tasks and set due dates to ensure nothing gets missed and eliminate duplicate work."
                      : "Add to-dos and set due dates to ensure nothing gets missed."
                  }
                  Icon={EmptyTodoIcon}
                />
              )}
              <Todos
                createTodo={() => createTodo(elevatedUser?.id || filtersState)}
                completeTodos={completeTodos}
                incompleteTodos={incompleteTodos}
                isPrivateTodos
                assigneeOptions={assigneeOptions}
              />
            </div>
          </Tabs.Item>
          <Tabs.Item
            name="Clients"
            header={getHeader("Clients", clientIncompleteTodos)}
          >
            <div className="mt-4">
              {!allClientTodos?.length && (
                <TodoEmptyState
                  Icon={EmptyTodoIcon}
                  title="All of your client to-dos in one place"
                  description="As you and your clients create to-do items over time, Practice will aggregate a list of all to-dos in one central place."
                />
              )}
              {clientsWithTodos?.map((c) => {
                const clientAssignee = members?.find(
                  (m) => m.id === c.assigneeId
                );
                return (
                  <ClientTodoSection
                    key={c.id}
                    client={c}
                    assigneeId={filtersState}
                    selectedSort={selectedSort}
                    clientAssignee={clientAssignee}
                  />
                );
              })}
            </div>
          </Tabs.Item>
        </Tabs>
      </>
    </SideModal>
  );
};

export default PrivateTodosSideModal;
