import { FC } from "react";
import { BillingRateType } from "@practice/sdk";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";
import { useGetTier } from "@hooks/use-tier";
import useToggle from "@hooks/use-toggle";
import { useCheckScreenSize } from "@hooks/use-window-size";
import pluralHelper from "@lib/utils/pluralHelper";

import { Button } from "@components/Button";
import LogIcon from "@components/Icons/LogIcon";
import Modal from "@components/Modals/Modal";
import { SelectableListContextProvider } from "@components/SelectableList";

import AddBillableRequest from "./AddBillableRequest";
import BillableRequestFilters from "./BillableRequestFilters";
import {
  BillableRequestsProvider,
  useGetBillableRequestsContext,
} from "./BillableRequestsContext";
import BillableRequestsTableList from "./BillableRequestsTableList";

export enum ModeType {
  COACH = "coach",
  REVIEWER = "reviewer",
}

const useGetBillableReviewerConditional = () => {
  const { organization, aid } = useAuth();
  const reviwerers =
    organization?.sessionBillingSettings?.billableReviewers || [];

  return reviwerers.some((reviewer) => reviewer.accountId === aid);
};

const HomePageBillableReviewerBanner: FC<{
  toggle: () => void;
}> = ({ toggle }) => {
  const { numbers } = useGetBillableRequestsContext();

  if (!numbers) return null;

  return (
    <div className="rounded-lg -mt-4 mb-4 bg-grey-950/60 border border-black-ink/7 p-3 flex items-center">
      <LogIcon className="h-6 w-6 text-grey-800" />
      <div className="ml-3 text-sm">
        <div>
          {numbers.submitted
            ? "Requests waiting for review"
            : "Billable time queue empty"}
        </div>
        <div className="text-grey-500">
          {numbers.submitted
            ? `${pluralHelper(
                numbers.submitted,
                "billable request"
              )} are waiting for
          review `
            : "There are no requests at this time for review. We'll let you know when there is!"}
        </div>
      </div>
      <Button
        className={classNames(
          "ml-auto",
          !numbers.submitted && "bg-grey-950 border border-black-ink/5"
        )}
        small
        primary={!!numbers.submitted}
        onClick={toggle}
      >
        {numbers.submitted ? "Review requests" : "View all requests"}
      </Button>
    </div>
  );
};

export const HomePageReviewerBillableRequestsModal: FC = () => {
  const { toggle, value: show } = useToggle();
  const isReviewer = useGetBillableReviewerConditional();
  const { isMD } = useCheckScreenSize();

  if (!isReviewer || !isMD) return null;

  return (
    <BillableRequestsProvider mode={ModeType.REVIEWER}>
      <HomePageBillableReviewerBanner toggle={toggle} />
      {show && <BillableRequestsModal show={true} toggle={toggle} />}
    </BillableRequestsProvider>
  );
};

const MemberSidebarBillableReviewerBanner: FC<{
  toggle: () => void;
}> = ({ toggle }) => {
  const { numbers } = useGetBillableRequestsContext();

  if (!numbers) return null;

  return (
    <div
      className={classNames(
        "border border-grey-900 rounded-lg flex items-center p-3 bg-gradient-to-b",
        numbers.submitted
          ? "from-action-950 to-action-950/20"
          : "from-grey-950 to-white"
      )}
    >
      <div
        className={classNames(
          "text-sm",
          numbers.submitted ? "text-action-400" : "text-grey-500"
        )}
      >
        {numbers.submitted
          ? `${pluralHelper(numbers.submitted, "Billable time request")}`
          : "No billable time requests"}
      </div>
      <Button
        className={classNames(
          "ml-auto text-sm",
          !numbers.submitted && "bg-white border border-grey-900"
        )}
        smaller
        primary={!!numbers.submitted}
        onClick={toggle}
      >
        {numbers.submitted ? "Review requests" : "View requests"}
      </Button>
    </div>
  );
};

export const TeamsSidebarReviewerBillableRequestsModal: FC = () => {
  const { toggle, value: show } = useToggle();
  const isReviewer = useGetBillableReviewerConditional();
  const { isMD } = useCheckScreenSize();

  if (!isReviewer || !isMD) return null;

  return (
    <>
      <MemberSidebarBillableReviewerBanner toggle={toggle} />
      <BillableRequestsModal show={show} toggle={toggle} hideMemberFilter />
    </>
  );
};

export const CoachBillableRequestsModal: FC = () => {
  const { value: show, toggle } = useToggle();
  const { aid } = useAuth();

  const { isFetching, activeBillingRates } = useBillingRatesContext();

  const hasAtLeastOneBillableRate = !!activeBillingRates?.filter(
    (rate) => rate.type === BillingRateType.Billable
  ).length;

  const tier = useGetTier();

  if (isFetching || !hasAtLeastOneBillableRate) return null;

  if (tier !== "business") return null;

  return (
    <>
      <Button
        className="fixed z-40 bottom-6 right-48 shadow-thin group p-3"
        icon={<LogIcon />}
        white
        circle
        data-heap-event-name="billalbe_request_modal_open_button_clicked"
        onClick={toggle}
      />
      {show && (
        <BillableRequestsProvider
          defaultFilters={{ accountId: aid }}
          mode={ModeType.COACH}
        >
          <BillableRequestsModal show={true} toggle={toggle} />
        </BillableRequestsProvider>
      )}
    </>
  );
};

const BillableRequestsModal: FC<{
  show: boolean;
  toggle: () => void;
  hideMemberFilter?: boolean;
}> = ({ show, toggle, hideMemberFilter }) => {
  const { mode } = useGetBillableRequestsContext();
  const { isMD } = useCheckScreenSize();

  return (
    <Modal
      show={show}
      toggleShow={toggle}
      size={isMD ? "biggest" : "bottom"}
      className={classNames("flex flex-col", isMD && "p-6 h-4/5")}
    >
      {isMD && (
        <>
          <h3 className="text-2xl">Billable Requests</h3>
          <SelectableListContextProvider>
            <div className="h-100 flex flex-col mb-auto overflow-hidden h-full">
              <BillableRequestFilters hideMemberFilter={hideMemberFilter} />
              <main className="main flex-1 overflow-auto">
                <BillableRequestsTableList />
              </main>
            </div>
          </SelectableListContextProvider>
        </>
      )}
      {mode === ModeType.COACH && <AddBillableRequest />}
    </Modal>
  );
};

export default BillableRequestsModal;
