import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DuplicateIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7V6C17 4.34315 15.6569 3 14 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H7V18C7 19.6569 8.34315 21 10 21H18C19.6569 21 21 19.6569 21 18V10C21 8.34315 19.6569 7 18 7H17ZM14 5H6C5.44772 5 5 5.44772 5 6V14C5 14.5523 5.44772 15 6 15H7V10C7 8.34315 8.34315 7 10 7H15V6C15 5.44772 14.5523 5 14 5ZM9 10C9 9.44772 9.44772 9 10 9H18C18.5523 9 19 9.44772 19 10V18C19 18.5523 18.5523 19 18 19H10C9.44772 19 9 18.5523 9 18V10Z"
      />
    </svg>
  );
};

export default DuplicateIcon;
