import React, { FC } from "react";
import classNames from "classnames";

const VerticalSeparator = () => {
  return (
    <div className="block h-full py-1">
      <div className="border-l border-gray-500 h-full"></div>
    </div>
  );
};

interface StepsItemProps {
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  showSeparator?: boolean;
  titleClassNames?: string;
  subtitleClassNames?: string;
  iconWrapperClassNames?: string;
}

const StepsItem: FC<StepsItemProps> = ({
  icon,
  title,
  subtitle,
  showSeparator = false,
  titleClassNames,
  subtitleClassNames,
  iconWrapperClassNames,
}) => (
  <>
    {icon && (
      <div
        className={classNames(
          "justify-self-center self-center",
          iconWrapperClassNames
        )}
      >
        {icon}
      </div>
    )}
    <div className={classNames("col-span-5 self-center", titleClassNames)}>
      {title}
    </div>
    <div className="justify-self-center">
      {showSeparator && <VerticalSeparator />}
    </div>
    <div className={classNames("col-span-5  mb-5", subtitleClassNames)}>
      {subtitle}
    </div>
  </>
);

export default StepsItem;
